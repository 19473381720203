<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title masked1">密码重置</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="ms-content">
                <el-form-item prop="telephone">
                    <el-input v-model="ruleForm.telephone" placeholder="请输入手机号" prefix-icon="el-icon-mobile"
                              class="username" id="telephone" @keyup.enter.native="submitForm('ruleForm')">
                    </el-input>
                </el-form-item>
                <el-form-item prop="code">
                    <el-col :span="15">
                        <el-input v-model="ruleForm.code" placeholder="请输入验证码" prefix-icon="el-icon-message"
                                  class="username"></el-input>
                    </el-col>
                    <el-col :span="7" style="margin-left: 15px">
                        <el-button type="primary" size="medium"  @click="getCode"
                                   id="getcode_btn" autocomplete="new-password">获取验证码
                        </el-button>
                    </el-col>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" placeholder="请输入密码" prefix-icon="el-icon-lx-lock"
                              v-model="ruleForm.password" class="pwd" autocomplete="new-password" @keyup.enter.native="submitForm('ruleForm')">
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm('ruleForm')" :loading="loading" id="login-btn">重置
                    </el-button>
                </div>
                <div>
                    <a href="/login" style="color:white;">返回登录</a>
                </div>
            </el-form>

        </div>
    </div>
</template>

<script>
    import USER from '../api/base/api_user'

    export default {
        data() {
            let checkPhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('手机号不能为空'));
                } else {
                    const reg = /^1[0-9][0-9]\d{8}$/
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            return {
                lock: false,
                loading: false,
                ruleForm: {
                    userName: '', password: '',
                    telephone: '', code: ''
                },
                rules: {
                    userName: [
                        {required: true, message: '用户名不能为空', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '密码不能为空', trigger: 'blur'}
                    ],
                    telephone: [
                        {validator: checkPhone, trigger: 'blur'}
                    ],
                    code: [
                        {required: true, message: '请输入正确的验证码', trigger: 'blur'}

                    ]
                },
                verifycode: '',
                checked: true,
                totalTime: 60,
                clock: null
            }
        },
        methods: {
            getCode() {
                if (this.lock) {
                    return;
                }
                let reg = /^1[0-9][0-9]\d{8}$/
                if (this.ruleForm.telephone === '' || !reg.test(this.ruleForm.telephone)) {
                    this.$message.error('请输入正确的手机号码！');
                    return;
                }
                this.lock = true;
                let codeData = {
                    telephone: this.ruleForm.telephone,
                    ack: (new Date().getDate() * 33 - 11) * 22,
                    token: this.ruleForm.telephone.substr(this.ruleForm.telephone.length - 4)
                }
                USER.getcode(codeData).then((res => {
                    if (res.code === 200) {
                        this.clock = window.setInterval(() => {
                            if (this.totalTime <= 0) {
                                this.lock = false
                                clearInterval(this.clock)
                                this.totalTime = 60
                                $("#getcode_btn").html("获取验证码");
                                $("#getcode_btn").css({
                                    "background": 'rgba(42, 80, 153, 0.8)',
                                    'border-color': '#409EFF',
                                    'cursor': 'default'
                                })
                            } else {
                                this.lock = true
                                this.totalTime--
                                $("#getcode_btn").html(this.totalTime + "秒后获取");
                                $("#getcode_btn").css({
                                    "background": 'rgba(245,108,108,0.3)',
                                    'border-color': 'rgba(245,108,108,0.3)',
                                    'cursor': 'not-allowed'
                                })
                            }

                        }, 1000)
                        this.$message.success("验证码已发送至你的手机，请注意查收！");
                        this.verifycode = res.data;
                    } else {
                        this.lock = false;
                        this.$message.error(res.msg);
                    }
                }))
            },
            submitForm: function (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let resetdata = {
                            userName: this.ruleForm.userName,
                            telephone: this.ruleForm.telephone,
                            password: this.ruleForm.password,
                            code: this.ruleForm.code,
                            ack: (new Date().getDate() * 33 - 11) * 22,
                        };
                        USER.resetpassword(resetdata)
                            .then(
                                (result) => {
                                    if (result && result.code == 200) {
                                        this.$message.success("密码重置成功，请登录!");
                                        this.$router.push('/login');
                                    } else if (result.code == 500) {
                                        this.$message.error({
                                            showClose: true,
                                            message: result.msg || "重置失败",
                                            duration: 2000
                                        });
                                    }
                                }
                            ).catch((err) => {
                            this.$message.error({
                                showClose: true,
                                message: err.message,
                                duration: 2000
                            });
                        });
                    }
                });
            },

        }
        , mounted() {
            //  localStorage.removeItem("access-token");
            //回车登陆事件

        }
    }
</script>

<style scoped>
    .login-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url(../assets/img/login-bg.jpg);
        background-size: 100%;
    }
    .el-form-item{
        margin-bottom: 10px;
    }
    .ms-login {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 450px;
        margin: -190px 0 0 -175px;
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.4);
        overflow: hidden;
    }

    .ms-title {
        width: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 22px;
        color: white;
        border-bottom: 1px solid rgb(4, 201, 255)
    }

    .ms-content {
        padding: 10px 30px;
        overflow: hidden;
    }

    .login-btn {
        text-align: center;
    }

    .login-btn button {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        height: 38px;
        margin-bottom: 10px;
        background: #05caff;
    }

    .login-tips {
        font-size: 14px;
        line-height: 30px;
        color: #888B95;
    }

    .login-tips:hover {
        text-decoration: none;
        color: #409EFF;
    }
</style>
